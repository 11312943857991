<template>
  <div class="login-wrapper">
    <div v-if="showErrorMessage"><strong>Sorry.</strong> Could not sign in with Google.</div>
    <button class="es-btn orange" :disabled="busy" @click.prevent="login(provider='google')">
      <fa :icon="['fab', 'google']" />
      Sign in with Google
    </button>

    <button class="es-btn blue" :disabled="busy" @click.prevent="login(provider='ms')">
      <fa :icon="['fab', 'microsoft']" />
      Sign in with Microsoft
    </button>

  </div>
</template>
<script>
import { mapActions } from 'vuex'

export default {
  name: 'Login',
  data() {
    return {
      busy: false,
      showErrorMessage: false,
    }
  },
  methods: {
    ...mapActions({
      accountLogin: 'account/login',
      accountLoginMS: 'account/loginMS'
    }),
    async login(provider="google") {
      this.busy = true
      try {
        if(provider === "google") {
          await this.accountLogin()
        }
        else if(provider === "ms") {
          await this.accountLoginMS()
        }
      } catch (error) {
        console.error(error)
        this.showErrorMessage = true
        this.busy = false
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.login-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 14rem;
  flex-direction: column;
}

.es-btn{
  width: 250px;
  height: 50px;
  margin-bottom: 1em;
}
</style>